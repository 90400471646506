#react-tags-label {
  font-family: "IranSans-Medium";
  font-size: 16px;
  direction: rtl;
}

#react-tags-combobox input {
  direction: rtl;
  float: right;
  width: 97% !important;
  height: 50px;
  border: 0.8px solid #979494;
  border-radius: 7px;
  font-family: "IranSans-Regular";
  font-size: 14px;
  padding-right: 10px;
  margin-top: 15px;
}
#react-tags-combobox input:focus {
  border: 0.8px solid #dd1c1d;
  outline: none;
}

#react-tags-listbox {
  float: right;
  font-family: "IranSans-Regular";
  font-size: 14px;
  direction: rtl;
}
#react-tags-listbox div {
  margin-top: 10px;
  padding: 10px;
  background-color: #979494;
  float: right;
  margin-left: 10px;
  border-radius: 7px;
  color: #fff;
}
.react-tags__list-item button {
  border: none;
  padding: 10px;
  border-radius: 7px;
  background-color: #2f80ed;
  color: #fff;
  float: right;
  font-family: "IranSans-Regular";
  font-size: 14px;
  direction: rtl;
  margin-left: 10px;
}
.react-tags__list {
  list-style: none;
}
